import "./TripStyles.css";
import TripData from "./TripData";
import Trip1 from "../assets/1.jpg";
import Trip2 from "../assets/2.jpg";
import Trip3 from "../assets/3.jpg";

function Trip() {
  return (
    <div className="trip">
      <h1>Recent Trips</h1>
      <p>You can discover unique destination using Google Maps.</p>
      <dir className="tripcard">
        <TripData
          image={Trip1}
          heading="Trip in Shimla"
          text="A trip to Shimla, the capital of Himachal Pradesh, is a delightful escape into the cool, scenic beauty of the Himalayas. Popular for its colonial architecture, bustling Mall Road, and panoramic views, Shimla offers a mix of relaxation and exploration."
        />
        <TripData
          image={Trip2}
          heading="Trip in Manali"
          text="A trip to Manali is a journey into nature’s paradise, perfect for adventure enthusiasts and those seeking serenity. Located in the Kullu Valley of Himachal Pradesh, Manali offers scenic beauty with its snow-capped mountains, lush greenery, and flowing rivers."
        />
        <TripData
          image={Trip3}
          heading="Trip in Kasol"
          text="A trip to Kasol promises a peaceful escape into nature, set along the scenic Parvati River in Himachal Pradesh. Known for its serene beauty and laid-back vibe, Kasol is a popular destination for backpackers."
        />
      </dir>
    </div>
  );
}

export default Trip;
