import "./AboutUsStyles.css";

function AboutUs() {
  return (
    <div className="about-container">
      <h1>Our History</h1>
      <p>
        In the early 2020s, a young visionary named Aryan, with a deep passion
        for the mountains and travel, set out to create something unique. Having
        explored the majestic landscapes of Himachal Pradesh and other Himalayan
        regions, Aryan realized the need for a dedicated platform that could
        guide travelers through the hidden gems and pristine beauty of the
        mountains. Thus, Himvelly was born, a travel website aimed at bringing
        the wonder of the Himalayas to the world.
      </p>

      <h1>Our Mission</h1>
      <p>
        The mission of Himvelly is to inspire and guide travelers in discovering
        the true essence of the Himalayas, fostering meaningful connections with
        nature, culture, and local communities. By offering personalized travel
        experiences, sustainable tourism options, and in-depth insights into
        offbeat destinations, Himvelly aims to create unforgettable journeys
        that not only showcase the beauty of the mountains but also promote
        eco-conscious and responsible travel.
      </p>

      <h1>Our Vision</h1>
      <p>
        Our Vision at Himvelly is to become the premier gateway for exploring
        the untouched beauty and rich culture of the Himalayas, while setting
        new standards for sustainable and responsible tourism.
      </p>
      <p>
        "Travel is not just about seeing places; it’s about leaving a positive
        footprint on the world you explore."
      </p>
    </div>
  );
}

export default AboutUs;
