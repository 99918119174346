import Mountain1 from "../assets/m.jpg";
import Mountain2 from "../assets/s.jpg";
import Mountain3 from "../assets/k.jpg";
import Mountain4 from "../assets/sv.jpg";
import DestinationData from "./DestinationData";
import "./DestinationStyles.css";

const Destination = () => {
  return (
    <div className="destination">
      <h1>Popular Destination</h1>
      <p>A Journey Through Nature and Serenity</p>
      <DestinationData
        className="first-des"
        heading="Shimla, Manali"
        text="Shimla and Manali are two of the most popular hill stations in Himachal Pradesh, offering distinct yet equally captivating experiences. Shimla, the state capital, is known for its colonial-era architecture, bustling Mall Road, and panoramic views from The Ridge. It's a perfect blend of history, culture, and nature, with attractions like Jakhoo Temple and the nearby Kufri for snow lovers. On the other hand, Manali, nestled in the Kullu Valley, is famous for its adventure activities like trekking, skiing, and paragliding. With stunning spots like Solang Valley, Rohtang Pass, and the ancient Hidimba Temple, Manali is a haven for thrill-seekers and nature enthusiasts alike. Both destinations offer breathtaking landscapes, making them must-visit places in Himachal Pradesh."
        img1={Mountain1}
        img2={Mountain2}
      />

      <DestinationData
        className="first-des-reverse"
        heading="Kasol, Spiti Valley"
        text="Kasol and Spiti Valley are two of Himachal Pradesh's most captivating destinations, each offering a unique experience for travelers. Kasol, set along the Parvati River, is a haven for backpackers and nature lovers, known for its lush landscapes, vibrant cafes, and serene treks like Kheerganga. It has a laid-back, bohemian vibe, making it popular among adventure seekers and those looking to unwind amidst nature. Spiti Valley, on the other hand, is a remote high-altitude desert, known for its rugged beauty and ancient Buddhist culture. With its barren landscapes, centuries-old monasteries like Key and Tabo, and serene lakes such as Chandratal, Spiti is ideal for those seeking solitude, spiritual exploration, and adventure in one of the most isolated and breathtaking regions of India."
        img1={Mountain3}
        img2={Mountain4}
      />
    </div>
  );
};

export default Destination;
