import "./ContactFormStyles.css";

function ContactForm() {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "352a055e-f556-4843-9932-317010500939");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      window.alert("Your message has been sent successfully!");
    } else {
      window.alert(
        "There was an error sending your message. Please try again."
      );
    }
  };

  return (
    <div className="from-container">
      <h1>Send a message to us!</h1>
      <form onSubmit={onSubmit}>
        <input name="name" placeholder="Name" required />
        <input name="Email" placeholder="Email" required />
        <input name="Subject" placeholder="Subject" required />
        <textarea
          name="message"
          placeholder="Message"
          rows="4"
          required
        ></textarea>
        <button type="submit">Send</button>
      </form>
    </div>
  );
}

export default ContactForm;
